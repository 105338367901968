
import React, { useState, useEffect } from 'react';
import './MessagePopup.css';
import Poopup from "./Popup";

function MessagePopup(props) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true); 
    }, 1000);


  }, []);
  return (

    <div className={`hideable-element ${isVisible ? 'show' : ''} cursor-pointer  `}>
      <div className="message-box text-md px-4 py-1 md:text-lg md:px-6 md:py-[0.7rem]  hover:transform hover:scale-x-125 hover:scale-y-125 hover:transition-all ease-in-out hover:ease-in-out duration-150 hover:text-[#222c4e] font-medium hover:font-medium">

        {/*<Poopup msg={props["msg"]}></Poopup>*/}
        {props["msg"]}
      </div>

    </div>
  );
}

export default MessagePopup;
