
import blok_icon from "./Assets/Images/Blok_logo_.png";
import './App.css';
import logo from "./Assets/Images/HatchfulExport-All/logo_transparent.png";
import { TypeAnimation } from 'react-type-animation';
import { ExportOutlined } from '@ant-design/icons';
import { Card } from 'antd';
import animationData from './Assets/gifs/Blok_logo_.gif';
import MessagePopup from "./MessagePopup";
import React, { useState } from "react";
import Poopup from "./Popup";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import GitHubIcon from '@mui/icons-material/GitHub';


const style = {
  position: 'absolute',

  left: '50%',
  transform: 'translate(-50%, 0%)',
  bgcolor: '#fff'
};



function App() {
  const { Meta } = Card;

  const [view, setView] = useState(false);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };



  return (

    <div className='bg-[#222c4e]  min-h-[100vh] w-full pb-10'>
      <div className='min-h-[100%] w-full bg relative  lg:bg-bottom bg-top'>
        <div className='bg-transparent block'>
          <div className=' mb-16 xl:mb-20 w-[40vmin] md:w-[23vmin]'>
            <div className=" mt-[-3vmin] left-[-1vmin]">

              <a href='/'> <img src={logo} alt="" /></a>
            </div>
          </div>
        </div>
        <h3 className='heading xl:ml-9 xl:max-w-[50%] 2xl:mt-[12vh]   xl:mb-16 text-center xl:text-left'>
          <TypeAnimation
            sequence={[
              // Same substring at the start will only be typed once, initially
              'What we do',
              2000,
              'We consult for Web devlopment',
              2000,
              'We consult for Data engineering',
              2000,
              'We consult for innovative it projects',
              2000,
            ]}
            speed={2}
            repeat={Infinity}
          />
        </h3>
        <div className='w-[100%] static xl:w-[50%] xl:absolute xl:top-[5%] xl:right-[0px] p-[5%]'>
          <img src={animationData} alt="Your GIF" className=" mx-auto w-full" />
        </div>

        {/*<div className='w-[100%] static mx-auto xl:w-[60%] xl:absolute xl:top-[-2%] xl:right-[0px] '>
          <Lottie animationData={animationData} loop={true} />
        </div>*/}

        <div className="descreption mx-9 xl:max-w-[50%] mb-15  xl:mb-36 text-center xl:text-left ">
          <h4>We empower businesses with comprehensive IT consultancy, offering project expertise and seamless outsourcing solutions.
            Your IT success is our mission - from project delivery to outsourced excellence, we're your dedicated consultancy partner.</h4>
        </div>
        <div className='w-[90%] lg:w-[40%]  mt-10 mx-auto  xl:ml-9'>
          <h3 className="icon-text font-semibold text-center xl:text-left">Our Projects: </h3>
          <div className="block">
            <Card
              className="border-[0px] w-[45%] mx-auto xl:mx-0 xl:w-[30%]  my-10  p-[1px] pb-2 !rounded-2xl text-[3vmin] md:text-xs"
              cover={
                <div className="">
                  <img
                    alt="example"
                    src={blok_icon}
                    className="bg-[#ffd580]"

                  />
                </div>
              }
              actions={[<a href="https://blokit.app/" rel="noreferrer" target="_blank"  ><ExportOutlined className="text-sm xl:text-lg" /></a>
              ]}
            >
              <Meta
                title="Blok"
                description="A scheduling platform that lets users Book recreational spaces in advance."
                className="text-center" />
            </Card>
          </div>
        </div>
      </div>
      {/*
      <div className="absolute top-5 right-5 md:right-10 md:invisible" onClick={()=>setView(!view)}><MessagePopup msg="Hello"></MessagePopup> </div>
      <div className="absolute top-5 right-5 md:top-8 md:right-10 invisible md:visible " onClick={()=>setView(!view)}><MessagePopup msg="Hello again!"></MessagePopup> </div>
            */}


      <div className="absolute top-5 right-5 md:right-10 md:invisible" onClick={() => setOpen(!open)}><MessagePopup msg="Hello"></MessagePopup>  </div>
      <div className="absolute top-5 right-5 md:top-8 md:right-10 invisible md:visible " onClick={() => setOpen(!open)}><MessagePopup msg="Hello again!"></MessagePopup> </div>


      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        style={{ backdropFilter: "blur(5px)", backgroundColor: "#4764c061" }} className="overflow-y-scroll"
      >
        <Box sx={{ ...style }} className="w-full md:w-[90vw] xl:w-[65vw]  relative xl:text-center  md:top-[10vh]">
          <div className="md:px-24 py-10 px-12">
            <div onClick={handleClose} className=" text-gray-600 text-3xl   absolute top-0 right-0  cursor-pointer font-normal p-10 md:p-20 opacity-50">X</div>

            <h3 className="popup-heading-one font-bold   mt-16">✉️ Contact us : </h3>

            <h2 className="text-2xl mt-7">Contact us for information on what projects we do and what services we offer </h2>

            <hr className="text-[#151d37] my-9"></hr>

            <h2 className="text-lg font-extrabold mt-7 text-[#151d37]">Contact us for </h2>
            <h2 className="text-lg  mt-2 text-[#151d37]">Contact us for information on what projects we do and what services we offer </h2>
            <div className="mt-3 flex underline underline-offset-[6px]">
              <a href="mailto:example@example.com" className=" text-[#151d37] xl:mx-auto">example@example.com</a>
            </div>

            <hr className="text-[#151d37] my-9"></hr>

            <h2 className="text-lg font-extrabold mt-7 text-[#151d37]">Contact us for </h2>
            <h2 className="text-lg  mt-2 text-[#151d37]">Contact us for information on what projects we do and what services we offer </h2>
            <div className="mt-3 flex underline underline-offset-[6px] ">
              <a href="mailto:example@example.com" className="mt-4 text-[#151d37] xl:mx-auto">example@example.com</a>
            </div>
            <hr className="text-[#151d37] my-9"></hr>


          </div>

          <div className="py-9 px-16 md:px-32 bg-[#4764c061]">
            <div className="flex  items-center justify-between ">
              <a href="#" className="text-[#151d37] hover:text-blue-600 ">
                <TwitterIcon fontSize="large" />
              </a>
              <a href="#" className="text-[#151d37] hover:text-blue-600 ">
                <FacebookIcon fontSize="large" />
              </a>
              <a href="#" className="text-pu[#151d37] hover:text-purple-800 ">
                <InstagramIcon fontSize="large" />
              </a>
              <a href="#" className="text-[#151d37] hover:text-gray-800 ">
                <GitHubIcon fontSize="large" />
              </a>
            </div>

          </div>
        </Box>
      </Modal>


    </div>
  );
}

export default App;
